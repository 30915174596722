import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import galleryImages from "./features/brochure/images.json";

const bucketNames: any = {};

export const BrochurePage = () => {
  const imageBuckets = galleryImages.reduce((acc: any, image) => {
    const category = image.imageKey[0];
    const bucket = acc[category] || [];

    bucket.push(image);
    acc[category] = bucket;
    return acc;
  }, {});
  console.log(imageBuckets);

  return (
    <section className="navbar">
      <div className="sticle-logo"></div>
      <nav>
        <ul>
          <li>
            <a href="#discover-sticle">HOME</a>
          </li>
          <li>
            <a href="#our-vineyard">OUR VINEYARD</a>
          </li>
          <li>
            <a href="#our-vines">OUR VINES</a>
          </li>
          <li>
            <a href="#our-location">OUR LOCATION</a>
          </li>
        </ul>
      </nav>
      {Object.keys(imageBuckets).map((bucketKey: any) => {
        const bucket = imageBuckets[bucketKey];
        return (
          <div className="w-screen mt-24">
            <div className="flex flex-col gap-2 text-center my-8">
              <p className="text-3xl font-extrabold tracking-[-0.04em] text-black sm:text-5xl sm:leading-[3.5rem]">
                {bucketNames[bucketKey]?.title || `Section '${bucketKey}' needs a title`}
              </p>
              <p className="text-lg font-semibold text-slate-600">
                {bucketNames[bucketKey]?.title || `Section '${bucketKey}' needs a description`}
              </p>
            </div>
            <Gallery>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
                  gridTemplateRows: "repeat(auto-fit, 260px)",
                  gridAutoFlow: "dense",
                  gridGap: "0.3rem"
                }}
              >
                {bucket.map((image: any) => {
                  const s3BucketUrl =
                    "https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/brochure";
                  return (
                    <Item
                      original={`${s3BucketUrl}/${image.imageKey}`}
                      thumbnail={`${s3BucketUrl}/${image.thumbnailImageKey}`}
                      width={image.width}
                      height={image.height}
                    >
                      {({ ref, open }) => (
                        <img
                          ref={ref}
                          onClick={open}
                          src={`${s3BucketUrl}/${image.thumbnailImageKey}`}
                        />
                      )}
                    </Item>
                  );
                })}
              </div>
            </Gallery>
          </div>
        );
      })}
    </section>
  );
};

import React from "react";

export const LandingPage = () => {
    return (
        <>
            <section className="navbar">
                <div className="sticle-logo"></div>
                <nav>
                    <ul>
                        <li><a href="#discover-sticle">HOME</a></li>
                        <li><a href="#our-vineyard">OUR VINEYARD</a></li>
                        <li><a href="#our-vines">OUR VINES</a></li>
                        <li><a href="#our-location">OUR LOCATION</a></li>
                    </ul>
                </nav>
            </section>

            <section id="discover-sticle" className="content-section">
                <div className="content-section-title">
                    <h3 className="subtitle">ESTABLISHED 2019</h3>
                    <h1 className="header">DISCOVER STICLE</h1>
                    <div className="sticle-video">
                        <video width="100%" height="100%" controls loop autoPlay>
                            <source src="https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/videos/flyby_2020.mp4"
                                    type="video/mp4"/>
                            Your browser does not support video.
                        </video>
                    </div>
                </div>
                <div className="content-section-background-image"></div>
            </section>

            <section id="our-vineyard" className="content-section">
                <div className="content-section-title">
                    <h3 className="subtitle">STICLE VINEYARD</h3>
                    <h1 className="header">OUR VINEYARD</h1>
                    <p>Nestled 800 ft above sea level in the undulating Welsh landscape,
                        Sticle enjoys spectacular views, blending Heaven and Earth. For generations, its
                        undisturbed lands have benefited from a rich Celtic history.</p>
                </div>
                <div className="vineyard-content">
                    <div className="cards">
                        <div className="card" id="showbox-our-gardens">
                            <div className="image"
                                 style={{backgroundImage: "url('https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_1.jpg')"}}></div>
                            <span className="title">OUR GARDENS</span>
                        </div>
                        <div className="card" id="showbox-our-vines">
                            <div className="image"
                                 style={{backgroundImage: "url('https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_1.jpg')"}}></div>
                            <span className="title">OUR VINES</span>
                        </div>
                        <div className="card">
                            <div className="image"
                                 style={{backgroundImage: "url('https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/sticle-tractor.jpg')"}}></div>
                            <span className="title">OUR FACILITIES<br/><small>(COMING 2024)</small></span>
                        </div>
                        <div className="card">
                            <div className="image"
                                 style={{backgroundImage: "url('https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_events_1.jpg')"}}></div>
                            <span className="title">OUR EVENTS<br/><small>(COMING 2024)</small></span>
                        </div>
                    </div>
                </div>
            </section>

            <section id="our-vines" className="content-section">
                <div className="content-section-title">
                    <h3 className="subtitle">THE HEART OF THE VINEYARD</h3>
                    <h1 className="header">OUR VINES</h1>
                    <p>Sticle Vineyard is home to over 10,000 vines, growing on 25 acres of beautiful land. The family owned
                        vineyard was established in 2019 and will be limited to 10,000 bottles of each of its 3 Cuvée, so not to
                        overwork its vines.</p>
                </div>
                <div className="vines-content">
                    <div className="vines-image">
                        <img src="https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/white-grape.png"
                             alt="image of white grapes"/>
                    </div>
                    <div className="vines-breakdown">
                        <div className="vine-breakdown">
                            <h3 className="subtitle">3,400 VINES</h3>
                            <p className="header vine-name">CHARDONNAY</p>
                        </div>
                        <div className="vine-breakdown">
                            <h3 className="subtitle">3,300 VINES</h3>
                            <p className="header vine-name">PINOT NOIR</p>
                        </div>
                        <div className="vine-breakdown">
                            <h3 className="subtitle">3,300 VINES</h3>
                            <p className="header vine-name">PINOT MEUNIER</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="our-location" className="content-section">
                <div className="content-section-title">
                    <h3 className="subtitle">BEAUTIFULLY NESTLED IN SOUTH WEST WALES</h3>
                    <h1 className="header">OUR LOCATION</h1>
                </div>
                <div className="content-section-background-image"></div>
                <div className="location-content">
                    <div id="map" className="maps-container">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d17909.890768727986!2d-4.277241323390221!3d52.00298575669548!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xba48bb3c8bc6edc6!2sSticle%20Vineyard!5e1!3m2!1sen!2suk!4v1572185655078!5m2!1sen!2suk"
                            width="100%" height="100%" frameBorder="0" style={{border: 0}} allowFullScreen></iframe>
                    </div>
                </div>
            </section>

            <section className="footer">
                <div className="footer-content">
                    <div className="footer-copy">
                        <p className="copyright-notice">Copyright © 2023 Sticle Vineyard Ltd. All rights reserved.</p>
                        <p>Designed by the Racine Family with ❤️&nbsp;in <br/>South West Wales, UK.</p>
                    </div>
                    <div className="footer-contact">
                        <h2 className="subheader">CONTACT</h2>
                        <p>
                            Sticle Vineyard<br/>
                            Castle Road<br/>
                            Pencader<br/>
                            Carmarthenshire<br/>
                            SA39 9AN<br/>
                            <br/>
                            <a href="mailto:enquiry@sticlevineyard.co.uk">enquiry@sticlevineyard.co.uk</a>
                        </p>
                    </div>
                    <div className="footer-links">
                        <h2 className="subheader">LINKS</h2>
                        <ul>
                            <li><a href="#discover-sticle">Home</a></li>
                            <li><a href="#our-vineyard">Our vineyard</a></li>
                            <li><a href="#our-vines">Our vines</a></li>
                            <li><a href="#our-location">Our location</a></li>
                        </ul>
                    </div>
                </div>
            </section>
            <div id="our-vines-modal" className="modal-wrapper">
                <div className="overlay"></div>
                <div className="modal showbox">
                    <div className="showbox-content">
                        <button className="button button-active close-modal">Close gallery</button>
                        <h1 className="header">OUR VINES</h1>
                        <p>Sticle Vineyard is home to over 10,000 vines, growing on 25 acres of beautiful land. The family owned
                            vineyard was established in 2019 and will be limited to 10,000 bottles of each of its 3 Cuvée, so not
                            to overwork its vines.</p>
                    </div>
                    <div className="showbox-images">
                        <div className="owl-carousel owl-theme">
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_3.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_1.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_2.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_4.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_5.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_6.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_7.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_8.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_9.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_vines_10.jpg)"}}
                                className="showbox-image"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="our-gardens-modal" className="modal-wrapper">
                <div className="overlay"></div>
                <div className="modal showbox">
                    <div className="showbox-content">
                        <button className="button button-active close-modal">Close gallery</button>
                        <h1 className="header">OUR GARDENS</h1>
                        <p>Nestled 800 ft above sea level in the undulating Welsh landscape,
                            Sticle enjoys spectacular views, blending Heaven and Earth. For generations, its
                            undisturbed lands have benefited from a rich Celtic history.</p>
                    </div>
                    <div className="showbox-images">
                        <div className="owl-carousel owl-theme">
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_1.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_2.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_3.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_4.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_5.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_6.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_7.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_8.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_10.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_11.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_12.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_13.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_14.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_15.jpg)"}}
                                className="showbox-image"></div>
                            <div
                                style={{backgroundImage: "url(https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/carousel/our_gardens_16.jpg)"}}
                                className="showbox-image"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
